:root{
  --ps-primary-theme: #62a5a3;
  --ps-secondary-theme: #d6622c;
  --ps-tertiary-theme: #93caa9;  
}

#intro-main{
    display: flex; 
    justify-content: center;
    align-items: center;  
    height: 100vh;    
}

#intro-image img{
  max-width: 250px;
}

#intro-content{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#intro-content #about-me #am-name{
  text-transform: uppercase;
  color: var(--ps-secondary-theme);  
}

.sl-icons{
  padding-right: 15px;
}

.sl-icons i{
  padding-right: 2px;
}

.sl-icons .sl-text{
  vertical-align: top;
}

.sl-icons{
  font-size: 13px;
  vertical-align: middle;
}

.sl-icons i[class*="fa-"]{
  font-size: 20px;
  color: var(--ps-primary-theme);
}

@media only screen and (max-width: 768px){
  #intro-main{
    flex-wrap: wrap;
  }
}


